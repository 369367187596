import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import React, { Fragment } from 'react';
import { Modal } from '@redq/reuse-modal';
import { StaticQuery, graphql } from 'gatsby';

import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import { saasTheme } from '../common/theme/saas';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from '../common/assets/css/style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import { GlobalStyle, ContentWrapper } from '../containers/index.style';

function defineLayout(option, children){
  if(option === "special"){
    return(
      <ContentWrapper>
        {children}
      </ContentWrapper>
    )
  }else {
    return(
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        <Modal />
        {children}
        <Footer />
      </ContentWrapper> 
    )
  }
}

const Layout = ({ children, pageContext }) => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}

    render={data => { 
      return(
        <ThemeProvider theme={saasTheme}>
          <Fragment>  
            <GlobalStyle />
              {defineLayout(pageContext.layout, children) }
            <ResetCSS />
          </Fragment>
        </ThemeProvider>
      )
    }   
  }
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
