import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import RImage from '../../common/components/ResponsiveImage';
import Logo from '../../common/components/UIElements/Logo';
import Container from '../../common/components/UI/Container';
import FooterArea, {
  Left,
  Menu,
  CopyText,
  Social,
} from './footer.style';

const Footer = ({ logoStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        FOOTER {
          logo {
            childImageSharp {
              fixed(width: 150, height: 39) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          menu {
            link
            label
          }
          social {
            link
            icon {
              childImageSharp {
                fixed(width: 35, height: 35) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);
  const { logo, social } = Data.dataJson.FOOTER;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <Container className="Container">
        <Left>
          <Logo
            className="logo"
            href="/"
            logoSrc={logo.childImageSharp.fixed}
            title="Portare Group SAS"
            logoStyle={logoStyle}
          />
          <CopyText>
            Copyright © {year}
          </CopyText>
        </Left>
        <Menu>
          {/*menu.map(({ link, label }, index) => (
            <Link to={link} key={`footer-link-key-${index}`}>
              {label}
            </Link>
          ))*/}
        </Menu>
        <Social>
          {/*<SocialText>Social:</SocialText>*/}
          {social.map(({ link, icon }, index) => (
            <Link to={link} key={`footer-social-key-${index}`}>
              <RImage fixed={icon.childImageSharp.fixed} alt="social image" />
            </Link>
          ))}
        </Social>
      </Container>
    </FooterArea>
  );
};

Footer.propTypes = {
  logoStyle: PropTypes.object,
};

Footer.defaultProps = {
  logoStyle: {
    maxWidth: '140px',
    mr: [0, 0, 0, 0],
  }
};

export default Footer;
