const colors = {
  transparent: 'transparent', // 0
  black: '#000000',
  white: '#ffffff',
  headingColor: '#0f2137',
  textColor: '#5d646d',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  main : '#EF7D20',
  mainHover : '#ef9220',
  primary: '#0356FC',
  primaryHover: '#035bfc',
  secondary: '#dc8940',
  secondaryHover: '#dc8940',
  yellow: '#f5cc30',
  yellowHover: '#f6d144',
  clearBlue: '#04B2D9',
  mainBoxShadow: '0px 9px 19.74px 1.26px rgba(239, 146, 32, 0.57)',
  primaryBoxShadow: '0px 9px 19.74px 1.26px rgba(3, 91, 252, 0.57)',
  secondaryBoxShadow: '0px 9px 19.74px 1.26px rgba(220, 137, 64, 0.57)',
  clearBlueBoxShadow: '0px 9px 19.74px 1.26px rgba(4, 178, 217, 0.57)',
  basicBoxShadow: '0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)',
  basicFarBoxShadow: '5px 10px 10px -10px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)',
  basicLowboxShadow: '0px 10px 15px -8px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%)',
};

export default colors;


