import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base } from '../base';
import Img from "gatsby-image";

const Image = ({ fluid, fixed, alt, ...props }) => {

  const ImageWrapper = styled(Img)`
    {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
    ${base}
  `

  const ImageReturn = () => (
    fixed ? <ImageWrapper fixed={fixed} alt={alt} {...props} /> : <ImageWrapper fluid={fluid} alt={alt} {...props} />
  );

  return (
    <ImageReturn />
  );
};

Image.propTypes = {
  fluid: PropTypes.string.isRequired,
  fixed: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  m: 0,
};

export default Image;
